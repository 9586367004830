import React, { Component } from 'react';

export default class AppDescription extends Component {
    render() {
        return (
            <div className="app-description">
                <h1>Mám Vedlejšák</h1>
                <p>Kalkulačka pro zaměstnance s vedlejší OSVČ. Vypočte veškeré daně a odvody pro osoby s vedlejší OSVČ: daň z příjmů, sociální a zdravotní pojištění.</p>
            </div>
        );
    }
}