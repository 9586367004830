import React, { Component, CSSProperties } from 'react'

const styleBlock: CSSProperties = {
    display: 'block',
}

export default class TaxDescription extends Component {
    render() {
        return (
            <div className="app-description">
                <h1>Daňová povinnost</h1>
                <p>Zdanění příjmů z podnikání podléhá sazbě 15% ze základu daně. Základ daně se určuje jako příjmy - výdaje z podnikání.</p>
                <p><b>Praktický příklad</b>: <i>Pan Novák byl po celý rok 2019 zaměstnaný a výdělečnou činností dosáhl za rok 2019 příjmy 100 000 Kč. Své výdaje si uplatňoval paušálem 60%.
                        <br />Základ daně tedy činí 40 000 Kč (100 000 - 60 000).
                        <br />Daň z příjmů je 6 000 Kč (40 000 * 0,15).
                        <br /><br />Nedoplatek na dani z příjmů je nutné uhradit do posledního dne, kdy je možné podat daňové přiznání v daném roce.
                   </i>
                </p>
                <ins className="adsbygoogle"
                    style={styleBlock}
                    data-ad-client="ca-pub-7175286978469001"
                    data-ad-slot="7989089241"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
            </div>
        );
    }
}