import React, { Component } from 'react'
import Form, { FormData } from './Form'
import TableResults from './TableResults'
import AppDescription from './AppDescription';
import Navigation from './Navigation';
import TaxDescription from './TaxDescription';
import SocialDescription from './SocialDescription';
import HealthDescription from './HealthDescription';

export enum NavState {
  Home,
  Tax,
  Social,
  Health
}

type AppState = {
  submitted: boolean,
  formData: any,
  navState: NavState
}

class App extends Component<{}, AppState> {
  public refTableResults: any;

  constructor(props: any) {
    super(props);
    this.state = {
      submitted: false,
      formData: null,
      navState: NavState.Home
    }
    this.refTableResults = React.createRef();
  }

  handleMenuClick = (e: any, state: NavState) => {
    e.preventDefault();
    this.setState({ navState: state });
    for (var i = 0; i < e.target.parentElement.parentElement.children.length; i++) {
      e.target.parentElement.parentElement.children[i].classList.remove('active');
    }
    e.target.parentElement.classList.add('active');
  }

  handleSubmit = (formData: FormData) => {
    this.setState({ submitted: true, formData: formData }, () => {
      if (this.refTableResults.current) {
        this.refTableResults.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        })
      }
    });
  }

  render() {
    if (this.state.navState === NavState.Home && !this.state.submitted) {
      return (
        <div id="app-container">
          <div id="app-left-container">
            <div className="app-worker"></div>
          </div>
          <div id="app-right-container">
            <div className="app-header"></div>
            <Navigation handleClick={this.handleMenuClick} />
            <AppDescription />
            <Form handleSubmit={this.handleSubmit} />
          </div>
          <div className="clearfix"></div>
        </div>
      )
    }
    else if (this.state.navState === NavState.Home && this.state.submitted) {
      return (
        <div id="app-container">
          <div id="app-left-container">
            <div className="app-worker"></div>
          </div>
          <div id="app-right-container">
            <div className="app-header"></div>
            <Navigation handleClick={this.handleMenuClick} />
            <AppDescription />
            <Form handleSubmit={this.handleSubmit} />
          </div>
          <div className="clearfix"></div>
          <div id="table-results-wrapper" ref={this.refTableResults}>
            <TableResults formData={this.state.formData} />
          </div>
        </div>
      )
    }
    else if (this.state.navState === NavState.Tax) {
      return (
        <div id="app-container">
          <div id="app-left-container">
            <div className="app-worker"></div>
          </div>
          <div id="app-right-container">
            <div className="app-header"></div>
            <Navigation handleClick={this.handleMenuClick} />
            <TaxDescription />
          </div>
          <div className="clearfix"></div>
        </div>
      )
    }
    else if (this.state.navState === NavState.Social) {
      return (
        <div id="app-container">
          <div id="app-left-container">
            <div className="app-worker"></div>
          </div>
          <div id="app-right-container">
            <div className="app-header"></div>
            <Navigation handleClick={this.handleMenuClick} />
            <SocialDescription />
          </div>
          <div className="clearfix"></div>
        </div>
      )
    }
    else if (this.state.navState === NavState.Health) {
      return (
        <div id="app-container">
          <div id="app-left-container">
            <div className="app-worker"></div>
          </div>
          <div id="app-right-container">
            <div className="app-header"></div>
            <Navigation handleClick={this.handleMenuClick} />
            <HealthDescription />
          </div>
          <div className="clearfix"></div>
        </div>
      )
    }
  }
}

export default App