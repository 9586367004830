import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faQuestionCircle)

export enum ToolTipPosition {
    Right,
    Left
}

const ToolTip = (props: any) => {
    if (props.Text && (typeof props.Position === "undefined" || props.Position === ToolTipPosition.Right)) {
        return <span className="tooltip-wrapper"><FontAwesomeIcon icon={faQuestionCircle} className="tooltip-icon" /><span className="tooltip-text tooltip-text-right">{props.Text}</span></span>
    }
    else if (props.Text && props.Position === ToolTipPosition.Left) {
        return <span className="tooltip-wrapper"><span className="tooltip-text tooltip-text-left">{props.Text}</span><FontAwesomeIcon icon={faQuestionCircle} className="tooltip-icon" /></span>
    }
    else {
        return <span></span>
    }
};

export default ToolTip;