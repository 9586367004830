import React, { useState } from 'react';
import ToolTip from './ToolTip';

export enum FormRowType {
    Input,
    Select,
    CheckBox,
    Count,
    Number,
    Month
}

const FormRow = (props: any) => {
    const [valueNumber, setValueNumber] = useState((props.Type === FormRowType.Number || props.Type === FormRowType.Count) && props.Default ? props.Default : "");
    const [valueMonth, setValueMonth] = useState(props.Type === FormRowType.Month && props.Default ? props.Default : "");
    const [valueCheckBox, setValueCheckBox] = useState(props.Type === FormRowType.CheckBox && props.Default ? props.Default : false);
    const [valueSelect, setValueSelect] = useState((props.Type === FormRowType.Select) && props.Default ? props.Default : "");

    function numberWithSpaces(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    function onChangeNumber(e: any) {
        const re = /^[0-9 \b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setValueNumber(numberWithSpaces(e.target.value.replace(/\s/g, "")));
        }
    }

    function onChangeMonth(e: any) {
        const re = /^[0-9 \b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            if (parseInt(e.target.value, 10) >= 1 && parseInt(e.target.value, 10) <= 12)
                setValueMonth(numberWithSpaces(e.target.value.replace(/\s/g, "")));
        }
    }

    function onChangeCheckBox(e: any) {
        setValueCheckBox(e.target.checked);
        if (props.OnChange)
            props.OnChange(e);
    }

    function onChangeSelect(e: any) {
        setValueSelect(e.target.value);
    }

    function handleMinus(e: any) {
        e.preventDefault();
        let formRowElement = e.target.parentElement.getElementsByTagName("input")[0];
        var value = formRowElement.value;
        var valueAfter = parseInt(value, 10) - 1;
        if ((props.Type === FormRowType.Month && valueAfter >= 1 && valueAfter <= 12) || (props.Type !== FormRowType.Month && valueAfter >= 0))
            formRowElement.value = valueAfter;
    }

    function handlePlus(e: any) {
        e.preventDefault();
        let formRowElement = e.target.parentElement.getElementsByTagName("input")[0];
        var value = formRowElement.value;
        var valueAfter = parseInt(value, 10) + 1;
        if ((props.Type === FormRowType.Month && valueAfter >= 1 && valueAfter <= 12) || props.Type !== FormRowType.Month)
            formRowElement.value = valueAfter;
    }

    function handleFocus(e: any) {
        e.target.select();
    }


    if (props.Display !== false && props.Type === FormRowType.Input) {
        return (
            <div className="form-row">
                <span className="form-row-label"><label htmlFor={props.Id}>{props.Name}<ToolTip Text={props.ToolTipText} /></label></span>
                <span className="form-row-element"><input id={props.Id} ref={props.Ref} /><span className="form-row-after">{props.AfterText}</span></span>
            </div>
        );
    } else if (props.Display !== false && props.Type === FormRowType.Number) {
        return (
            <div className="form-row">
                <span className="form-row-label"><label htmlFor={props.Id}>{props.Name}<ToolTip Text={props.ToolTipText} /></label></span>
                <span className="form-row-element"><input onFocus={handleFocus} id={props.Id} ref={props.Ref} value={valueNumber} onChange={onChangeNumber} /><span className="form-row-after">{props.AfterText}</span></span>
            </div>
        );
    } else if (props.Display !== false && props.Type === FormRowType.Month) {
        return (
            <div className="form-row">
                <span className="form-row-label"><label htmlFor={props.Id}>{props.Name}<ToolTip Text={props.ToolTipText} /></label></span>
                <span className="form-row-element"><button className="form-btn-minus" onClick={handleMinus}></button> <input id={props.Id} ref={props.Ref} value={valueMonth} onChange={onChangeMonth} className="form-row-input-count" /> <button className="form-btn-plus" onClick={handlePlus}></button><span className="form-row-after">{props.AfterText}</span></span>
            </div>
        );
    } else if (props.Display !== false && props.Type === FormRowType.Select) {
        var options = [];
        if (props.Options && props.Options.length > 0) {
            for (let i in props.Options) {
                options.push(<option key={props.Options[i].Value} value={props.Options[i].Value}>{props.Options[i].Text}</option>);
            }
        }
        return (
            <div className="form-row">
                <span className="form-row-label"><label htmlFor={props.Id}>{props.Name}</label><ToolTip Text={props.ToolTipText} /></span>
                <span className="form-row-element"><select dir={props.Dir === "rtl" ? "rtl" : ""} id={props.Id} ref={props.Ref} value={valueSelect} onChange={onChangeSelect}>{options}</select><span className="form-row-after">{props.AfterText}</span></span>
            </div>
        );
    } else if (props.Display !== false && props.Type === FormRowType.Count) {
        return (
            <div className="form-row">
                <span className="form-row-label"><label htmlFor={props.Id}>{props.Name}</label><ToolTip Text={props.ToolTipText} /></span>
                <span className="form-row-element"><button className="form-btn-minus" onClick={handleMinus}></button> <input id={props.Id} ref={props.Ref} value={valueNumber} onChange={onChangeNumber} className="form-row-input-count" /> <button className="form-btn-plus" onClick={handlePlus}></button><span className="form-row-after">{props.AfterText}</span></span>
            </div>
        );
    } else if (props.Display !== false && props.Type === FormRowType.CheckBox) {
        return (
            <div className="form-row">
                <span className="form-row-label"><label htmlFor={props.Id}>{props.Name}</label><ToolTip Text={props.ToolTipText} /></span>
                <span className="form-row-element"><input id={props.Id} ref={props.Ref} className="toggle-switch" type="checkbox" onChange={onChangeCheckBox} checked={valueCheckBox} /><span className="form-row-after">{props.AfterText}</span></span>
            </div>
        );
    }
    else {
        console.log("Unsupported input type!")
        return (<div></div>);
    }
};

export default FormRow;