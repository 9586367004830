import React, { Component } from 'react';
import { NavState } from './App';

type NavProps = {
    handleClick: Function
}

export default class Navigation extends Component<NavProps> {
    render() {
        return (
            <ul className="app-navigation">
                <li className="active"><button className="nav-link" onClick={(e) => { this.props.handleClick(e, NavState.Home) }}>Domů</button></li>
                <li><button className="nav-link" onClick={(e) => { this.props.handleClick(e, NavState.Tax) }}>Daňová povinnost</button></li>
                <li><button className="nav-link" onClick={(e) => { this.props.handleClick(e, NavState.Health) }}>Zdravotní pojištění</button></li>
                <li><button className="nav-link" onClick={(e) => { this.props.handleClick(e, NavState.Social) }}>Sociální pojištění</button></li>
            </ul>
        );
    }
}