import React, { Component } from 'react'
import FormRow, { FormRowType } from './FormRow';

type FormProps = {
    handleSubmit: Function
}

export type FormData = {
    incomeWork: number;
    incomeBusiness: number;
    outcomeFlatRateYesNo: boolean;
    outcomeFlatRate: number;
    outcomeNumber: number;
    childrenCount: number;
    spouseDiscount: string;
    monthCount: number;
    incomeDiscounts: number;
    childSchool: number;
    year: number;
    insuranceWork: number;
    taxDepositWork: number;
    socialDeposit: number;
}

type FormState = {
    submitted: boolean;
    error: boolean;
    formData: FormData;
    outcomeFlatRateYesNo: boolean;
}

class Form extends Component<FormProps, FormState> {
    public refIncomeWork: any;
    public refIncomeBusiness: any;
    public refOutcomeFlatRateYesNo: any;
    public refOutcomeFlatRate: any;
    public refOutcomeNumber: any;
    public refChildrenCount: any;
    public refSpouseDiscount: any;
    public refMonthCount: any;
    public refIncomeDiscounts: any;
    public refChildSchool: any;
    public refYear: any;
    public refInsuranceWork: any;
    public refTaxDepositWork: any;
    public refSocialDeposit: any;

    constructor(props: FormProps) {
        super(props);
        this.state = {
            submitted: false,
            error: false,
            formData: {
                incomeWork: 0,
                incomeBusiness: 0,
                childSchool: 0,
                childrenCount: 0,
                incomeDiscounts: 0,
                monthCount: 0,
                outcomeFlatRate: 0,
                outcomeFlatRateYesNo: false,
                outcomeNumber: 0,
                spouseDiscount: "",
                year: 0,
                insuranceWork: 0,
                taxDepositWork: 0,
                socialDeposit: 0
            },
            outcomeFlatRateYesNo: true
        }
        this.refIncomeWork = React.createRef();
        this.refIncomeBusiness = React.createRef();
        this.refOutcomeFlatRateYesNo = React.createRef();
        this.refOutcomeFlatRate = React.createRef();
        this.refOutcomeNumber = React.createRef();
        this.refChildrenCount = React.createRef();
        this.refSpouseDiscount = React.createRef();
        this.refMonthCount = React.createRef();
        this.refIncomeDiscounts = React.createRef();
        this.refChildSchool = React.createRef();
        this.refYear = React.createRef();
        this.refInsuranceWork = React.createRef();
        this.refTaxDepositWork = React.createRef();
        this.refSocialDeposit = React.createRef();
    }

    submitForm = () => {
        // TODO check form
        this.setState({ submitted: true, error: false });
        let formData = this.state.formData;
        formData.incomeWork = parseInt(this.refIncomeWork.current.value.replace(/\s/g, ""), 10);
        formData.incomeBusiness = parseInt(this.refIncomeBusiness.current.value.replace(/\s/g, ""), 10);
        formData.taxDepositWork = parseInt(this.refTaxDepositWork.current.value.replace(/\s/g, ""), 10);
        formData.insuranceWork = parseInt(this.refInsuranceWork.current.value.replace(/\s/g, ""), 10);
        formData.childSchool = parseInt(this.refChildSchool.current.value.replace(/\s/g, ""), 10);
        formData.childrenCount = parseInt(this.refChildrenCount.current.value.replace(/\s/g, ""), 10);
        formData.incomeDiscounts = parseInt(this.refIncomeDiscounts.current.value.replace(/\s/g, ""), 10);
        formData.monthCount = parseInt(this.refMonthCount.current.value.replace(/\s/g, ""), 10);
        formData.outcomeFlatRate = parseInt(this.refOutcomeFlatRate.current?.value.replace(/\s/g, ""), 10);
        formData.outcomeNumber = parseInt(this.refOutcomeNumber.current?.value.replace(/\s/g, ""), 10);
        formData.socialDeposit = parseInt(this.refSocialDeposit.current?.value.replace(/\s/g, ""), 10);
        formData.outcomeFlatRateYesNo = this.refOutcomeFlatRateYesNo.current.checked;
        formData.spouseDiscount = this.refSpouseDiscount.current.value;
        formData.year = parseInt(this.refYear.current.value.replace(/\s/g, ""), 10);

        this.setState({ formData: formData }, () => {
            this.props.handleSubmit(this.state.formData);
        });
    }

    outcomeFlatRateYesNoOnChange = (e: any) => {
        this.setState({ outcomeFlatRateYesNo: e.target.checked });
    }

    render() {
        return (
            <form id="form-wrapper">
                <FormRow Id="year" Name="Rok" Ref={this.refYear} Type={FormRowType.Select} Default={(new Date()).getFullYear()} ToolTipText="Zdaňovací období" Options={[{ Text: "2019", Value: "2019" }, { Text: "2020", Value: "2020" }]} />
                <FormRow Id="incomeWork" Name="Příjmy (zaměstnavatel)" Ref={this.refIncomeWork} Type={FormRowType.Number} Default="400 000" AfterText=" Kč" ToolTipText="Řádek č. 2 z Potvrzení o zdanitelných příjmech" />
                <FormRow Id="insuranceWork" Name="Pojistné (zaměstnavatel)" Ref={this.refInsuranceWork} Type={FormRowType.Number} Default="200 000" AfterText=" Kč" ToolTipText="Řádek č. 6 z Potvrzení o zdanitelných příjmech" />
                <FormRow Id="taxWork" Name="Zálohy na daň (zaměstnavatel)" Ref={this.refTaxDepositWork} Type={FormRowType.Number} Default="50 000" AfterText=" Kč" ToolTipText="Řádek č. 12 z Potvrzení o zdanitelných příjmech" />
                <FormRow Id="incomeBusiness" Name="Příjmy z podnikání" Ref={this.refIncomeBusiness} Type={FormRowType.Number} Default="100 000" AfterText=" Kč" />
                <FormRow Id="outcomeFlatRateYesNo" Name="Výdaje paušálem" OnChange={this.outcomeFlatRateYesNoOnChange} Ref={this.refOutcomeFlatRateYesNo} Type={FormRowType.CheckBox} Default={true} />
                <FormRow Id="outcomeFlatRate" Name="Výdaje (paušál)" Display={this.state.outcomeFlatRateYesNo} Ref={this.refOutcomeFlatRate} Type={FormRowType.Select} Default="60" Options={[{ Text: "30 %", Value: "30" }, { Text: "40 %", Value: "40" }, { Text: "60 %", Value: "60" }, { Text: "80 %", Value: "80" }]} />
                <FormRow Id="outcomeNumber" Name="Výdaje (kč)" Display={!this.state.outcomeFlatRateYesNo} Ref={this.refOutcomeNumber} Type={FormRowType.Number} Default="0" AfterText=" Kč" />
                <FormRow Id="monthCount" Name="Počet měsíců podnikání" Ref={this.refMonthCount} Type={FormRowType.Month} Default="12" />
                <FormRow Id="socialDeposit" Name="Zálohy na sociální pojištění" Ref={this.refSocialDeposit} Type={FormRowType.Number} Default="0" AfterText=" Kč" ToolTipText="V prvním roce podnikání se neplatí" />
                <FormRow Id="childrenCount" Name="Počet dětí" Ref={this.refChildrenCount} Type={FormRowType.Count} Default="0" />
                <FormRow Id="spouseDiscount" Name="Sleva na manželku / manžela" Ref={this.refSpouseDiscount} Type={FormRowType.Select} Options={[{ Text: "Ne", Value: "ne" }, { Text: "Ano", Value: "ano" }, { Text: "Ano-ZTP/P", Value: "anoZTP" }]} Dir="rtl" />
                <FormRow Id="incomeDiscounts" Name="Odčítatelné položky z příjmu" Ref={this.refIncomeDiscounts} Type={FormRowType.Number} Default="0" AfterText=" Kč" ToolTipText="Zaplacené úroky, životní pojištění, penzijní pojištění, dary, darování krve, orgánů, kostní dřeně" />
                <FormRow Id="childSchool" Name="Umístění dítěte (školkovné)" Ref={this.refChildSchool} Type={FormRowType.Number} Default="0" AfterText=" Kč" />
                <input type="button" value="Spočítat" onClick={this.submitForm} id="form-submit" />
            </form>
        );
    }
}

export default Form; 