export class Constants {

    public static getSocialLimit(year: number) {
        switch (year) {
            case 2019: return 78476;
            case 2020: return 83603;
            default: return 83603;
        }
    }

    public static getTaxPayerDiscount(year: number) {
        switch (year) {
            case 2019: return 24840;
            case 2020: return 24840;
            default: return 24840;
        }
    }

    public static getSpouseDiscount(year: number) {
        switch (year) {
            case 2019: return 24840;
            case 2020: return 24840;
            default: return 24840;
        }
    }

    public static getChildrenDiscount(year: number, numChildren: number) {
        switch (year) {
            case 2019:
                if (numChildren === 1) return 15204;
                else if (numChildren === 2) return 15204 + 19404;
                else if (numChildren >= 3) return 15204 + 19404 + ((numChildren - 2) * 24204);
                else return 0;
            case 2020:
                if (numChildren === 1) return 15204;
                else if (numChildren === 2) return 15204 + 19404;
                else if (numChildren >= 3) return 15204 + 19404 + ((numChildren - 2) * 24204);
                else return 0;
            default:
                if (numChildren === 1) return 15204;
                else if (numChildren === 2) return 15204 + 19404;
                else if (numChildren >= 3) return 15204 + 19404 + ((numChildren - 2) * 24204);
                else return 0;
        }
    }

    public static getHealthInsurancePerc(year: number) {
        switch (year) {
            case 2019: return 0.135;
            case 2020: return 0.135;
            default: return 0.135;
        }
    }

    public static getSocialInsurancePerc(year: number) {
        switch (year) {
            case 2019: return 0.292;
            case 2020: return 0.292;
            default: return 0.292;
        }
    }

    public static getSocialMinDeposit(year: number) {
        switch (year) {
            case 2019: return 995;
            case 2020: return 1018;
            default: return 1018;
        }
    }

    public static numberWithSpaces(x: any) {
        x = Math.ceil(x);
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Kč";
    }
}