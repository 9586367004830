import React, { Component, CSSProperties } from 'react'
import { FormData } from './Form'
import ToolTip, { ToolTipPosition } from './ToolTip';
import { Constants } from './Constants'

const styleBlock: CSSProperties = {
    display: 'block',
}

type TableResultsProps = {
    formData: FormData
}

class TableResults extends Component<TableResultsProps> {
    incomeBusiness: number;
    outcome: number;
    taxBaseWork: number;
    taxBaseBusiness: number;
    healthInsurance: number;
    socialInsurance: number;
    tax: number;
    toolTipTexts: any;
    total: number;
    socialInsuranceTreshold: number;
    totalWithoutSocial: number;
    taxDiscount: number;
    taxBaseWithoutDiscount: number;
    insuranceWork: number;
    taxBaseWithoutDiscountFloor: number;

    constructor(props: TableResultsProps) {
        super(props);
        this.incomeBusiness = 0;
        this.outcome = 0;
        this.taxBaseWork = 0;
        this.taxBaseBusiness = 0;
        this.healthInsurance = 0;
        this.socialInsurance = 0;
        this.tax = 0;
        this.total = 0;
        this.socialInsuranceTreshold = 0;
        this.totalWithoutSocial = 0;
        this.taxDiscount = 0;
        this.taxBaseWithoutDiscount = 0;
        this.taxBaseWithoutDiscountFloor = 0;
        this.insuranceWork = 0;
        this.generateToolTipsTexts();
    }

    generateToolTipsTexts() {
        this.toolTipTexts = {
            socialInsuranceNumber: this.taxBaseBusiness <= this.socialInsuranceTreshold ? "Sociální pojištění nemusí být placeno, protože je hrubý zisk do limitu " + Constants.numberWithSpaces(this.socialInsuranceTreshold) : "Sociální pojištění musí být placeno, protože je hrubý zisk nad limit " + Constants.numberWithSpaces(this.socialInsuranceTreshold),
            socialInsuranceBase: "Polovina zisku",
            socialInsuranceSum: (Constants.getSocialInsurancePerc(this.props.formData.year) * 100) + "% z vyměřovacího základu sociálního pojištění. Při vedlejší činnosti nemusíte platit v prvním roce žádné zálohy. V dalších letech budete platit zálohy vypočtené podle zisku z minulého roku. Při nízkem zisku (limit pro rok ##YEAR## je ##SOCIAL_LIMIT## Kč) neplatíte sociální pojištění z podnikání vůbec, pokud nechcete.".replace("##YEAR##", this.props.formData.year.toString()).replace("##SOCIAL_LIMIT##", Constants.numberWithSpaces(Constants.getSocialLimit(this.props.formData.year))),
            healthInsuranceBase: "Polovina zisku",
            healthInsuranceSum: (Constants.getHealthInsurancePerc(this.props.formData.year) * 100) + "% z vyměřovacího základu zdravotního pojištění",
        };
    }

    render() {
        if (this.props.formData.outcomeFlatRateYesNo) {
            this.outcome = (this.props.formData.outcomeFlatRate / 100) * this.props.formData.incomeBusiness;
        }
        else {
            this.outcome = this.props.formData.outcomeNumber;
        }
        this.taxBaseBusiness = this.props.formData.incomeBusiness - this.outcome;
        this.taxBaseWork = this.props.formData.incomeWork + this.props.formData.insuranceWork;
        this.healthInsurance = (this.taxBaseBusiness / 2) * Constants.getHealthInsurancePerc(this.props.formData.year);
        this.socialInsurance = (this.taxBaseBusiness / 2) * Constants.getSocialInsurancePerc(this.props.formData.year) - this.props.formData.socialDeposit;
        this.socialInsuranceTreshold = ((Constants.getSocialLimit(this.props.formData.year) / 12) * this.props.formData.monthCount);
        this.taxDiscount = this.props.formData.incomeDiscounts;
        this.taxBaseWithoutDiscount = (this.taxBaseBusiness + this.taxBaseWork) - this.taxDiscount;
        this.taxBaseWithoutDiscountFloor = Math.floor(this.taxBaseWithoutDiscount / 100) * 100;
        var taxPayerDiscount = Constants.getTaxPayerDiscount(this.props.formData.year);
        var taxBeforeDiscount = (this.taxBaseWithoutDiscountFloor / 100) * 15;

        // Spouse discount
        var spouseDiscount = 0;
        if (this.props.formData.spouseDiscount !== "ne") {
            spouseDiscount = this.props.formData.spouseDiscount === "ano" ? Constants.getSpouseDiscount(this.props.formData.year) : Constants.getSpouseDiscount(this.props.formData.year) * 2;
        }

        // Children discount
        var childrenDiscount = 0;
        if (this.props.formData.childrenCount > 0) {

        }

        var taxAfterDiscount = taxBeforeDiscount - taxPayerDiscount - spouseDiscount - this.props.formData.childSchool;
        this.tax = taxAfterDiscount - this.props.formData.taxDepositWork - childrenDiscount;
        if (this.tax < 0) this.tax = 0;

        this.total = this.tax + this.healthInsurance + this.socialInsurance;
        this.totalWithoutSocial = this.tax + this.healthInsurance;

        this.generateToolTipsTexts();

        return (
            <div>
                <div className="table-results-middle">
                    <table>
                        <tbody>
                            <tr className="table-results-header top-row"><td colSpan={2}>Příjmy a výdaje</td></tr>
                            <tr className="table-results-row"><td>Příjmy ze zaměstnání</td><td>{Constants.numberWithSpaces(this.props.formData.incomeWork)}</td></tr>
                            <tr className="table-results-row"><td>Pojištění zaplacené zaměstnavatelem</td><td>{Constants.numberWithSpaces(this.props.formData.insuranceWork)}</td></tr>
                            <tr className="table-results-row"><td>Základ daně ze závislé činnosti (zaměstnání)</td><td>{Constants.numberWithSpaces(this.taxBaseWork)}</td></tr>
                            <tr className="table-results-row"><td>Příjmy z podnikání</td><td>{Constants.numberWithSpaces(this.props.formData.incomeBusiness)}</td></tr>
                            <tr className="table-results-row"><td>Výdaje z podnikání</td><td>{Constants.numberWithSpaces(this.outcome)}</td></tr>
                            <tr className="table-results-row"><td>Základ daně z podnikání (hrubý zisk)</td><td>{Constants.numberWithSpaces(this.taxBaseBusiness)}</td></tr>
                            <tr className="table-results-row"><td className="bold">Základ daně ze všech příjmů</td><td className="bold">{Constants.numberWithSpaces(this.taxBaseWork + this.taxBaseBusiness)}</td></tr>

                            <tr className="table-results-header middle-row"><td colSpan={2}>Daňová povinnost</td></tr>
                            <tr className="table-results-row"><td>Odčítatelné položky</td><td>{Constants.numberWithSpaces(this.taxDiscount)}</td></tr>
                            <tr className="table-results-row"><td>Základ daně po odčitatelných položkách</td><td>{Constants.numberWithSpaces(this.taxBaseWithoutDiscount)}</td></tr>
                            <tr className="table-results-row"><td>Zaokrouhlený základ daně</td><td>{Constants.numberWithSpaces(this.taxBaseWithoutDiscountFloor)}</td></tr>
                            <tr className="table-results-row"><td>Daň před slevami</td><td>{Constants.numberWithSpaces(taxBeforeDiscount)}</td></tr>
                            <tr className="table-results-row"><td>Z toho solidární přirážka</td><td>-</td></tr>
                            <tr className="table-results-row"><td>Sleva na poplatníka</td><td>{Constants.numberWithSpaces(taxPayerDiscount)}</td></tr>
                            <tr className="table-results-row"><td>Sleva na manželku / manžela</td><td>{Constants.numberWithSpaces(spouseDiscount)}</td></tr>
                            <tr className="table-results-row"><td>Umístění dítěte (školkovné)</td><td>{Constants.numberWithSpaces(this.props.formData.childSchool)}</td></tr>
                            <tr className="table-results-row"><td>Daň po uplatnění slev</td><td>{Constants.numberWithSpaces(taxAfterDiscount)}</td></tr>
                            <tr className="table-results-row"><td>Zvýhodnění na děti</td><td>{Constants.numberWithSpaces(childrenDiscount)}</td></tr>
                            <tr className="table-results-row"><td>Zaplacené zálohy na daň od zaměstnavatelů</td><td>{Constants.numberWithSpaces(this.props.formData.taxDepositWork)}</td></tr>
                            <tr className="table-results-row"><td className="bold">Doplatek daně</td><td className="bold">{Constants.numberWithSpaces(this.tax)}</td></tr>

                            <tr className="table-results-header middle-row"><td colSpan={2}>Zdravotní pojištění</td></tr>
                            <tr className="table-results-row"><td>Vyměřovací základ <ToolTip Text={this.toolTipTexts.healthInsuranceBase} /></td><td>{Constants.numberWithSpaces(this.taxBaseBusiness / 2)}</td></tr>
                            <tr className="table-results-row"><td className="bold">Zdravotní pojištění <ToolTip Text={this.toolTipTexts.healthInsuranceSum} /></td><td className="bold">{Constants.numberWithSpaces(this.healthInsurance)}</td></tr>

                            <tr className="table-results-header bottom-row"><td colSpan={2}>Sociální pojištění</td></tr>
                            <tr className="table-results-row"><td>Vyměřovací základ <ToolTip Text={this.toolTipTexts.socialInsuranceBase} /></td><td>{Constants.numberWithSpaces(this.taxBaseBusiness / 2)}</td></tr>
                            <tr className="table-results-row"><td>Zaplacené zálohy na sociální pojištění</td><td>{Constants.numberWithSpaces(this.props.formData.socialDeposit)}</td></tr>
                            <tr className="table-results-row"><td className="bold">Sociální pojištění <ToolTip Text={this.toolTipTexts.socialInsuranceSum} /></td><td className="bold"><ToolTip Text={this.toolTipTexts.socialInsuranceNumber} Position={ToolTipPosition.Left} /> {Constants.numberWithSpaces(this.socialInsurance)}</td></tr>

                            <tr className="table-results-header bottom-row"><td colSpan={2}>Celkem</td></tr>
                            <tr className="table-results-row"><td>Daně</td><td>{Constants.numberWithSpaces(this.tax)}</td></tr>
                            <tr className="table-results-row"><td>Zdravotní pojištění</td><td>{Constants.numberWithSpaces(this.healthInsurance)}</td></tr>
                            <tr className="table-results-row"><td>Sociální pojištění</td><td>{Constants.numberWithSpaces(this.socialInsurance)}</td></tr>
                            <tr className="table-results-row"><td className="bold">Celkem</td><td className="bold">{Constants.numberWithSpaces(this.total)}</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-results-bottom">
                    <ins className="adsbygoogle"
                        style={styleBlock}
                        data-ad-client="ca-pub-7175286978469001"
                        data-ad-slot="7989089241"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                    {this.taxBaseBusiness <= this.socialInsuranceTreshold ? "Celkem musíte zaplatit " + Constants.numberWithSpaces(this.totalWithoutSocial) + " (" + Constants.numberWithSpaces(this.total) + " se sociálním pojištěním)" : "Celkem musíte zaplatit " + Constants.numberWithSpaces(this.total)}
                </div>
            </div>
        );
    }
}

export default TableResults; 