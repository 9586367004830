import React, { Component, CSSProperties } from 'react'
import { Constants } from './Constants'

const styleBlock: CSSProperties = {
    display: 'block',
}

export default class SocialDescription extends Component {
    render() {
        return (
            <div className="app-description">
                <h1>Sociální pojištění</h1>
                <p>OSVČ vykonávající vedlejší samostatnou výdělečnou činnost platí sociální pojištění jenom v případě, když měl ředchozím roce významnější zisk nebo se k sociálnímu pojištění přihlásili dobrovolně.
                <br /><br />První rok se tedy zálohy na sociální pojištění neplatí vůbec. Druhý a každý další rok se zálohy platí, pokud nám v posledním "Přehledu příjmů" vyšlo, že zálohy platit musíme - náš zisk byl nad určitou stanovenou hranici.
                <br /><br />Pro rok {(new Date()).getFullYear()} je tento limit <b>{Constants.numberWithSpaces(Constants.getSocialLimit((new Date()).getFullYear()))}</b>. Pro {(new Date()).getFullYear() - 1} byl limit <b>{Constants.numberWithSpaces(Constants.getSocialLimit((new Date()).getFullYear() - 1))}</b> .
                <br /><br />Jesli zisk z podnikání za uplynulý rok ({(new Date()).getFullYear() - 1}) překročil tento limit, je minimální měsíční záloha v roce {(new Date()).getFullYear()} dána částkou <b>{Constants.numberWithSpaces(Constants.getSocialMinDeposit((new Date()).getFullYear()))}</b>.
                </p>
                <ins className="adsbygoogle"
                    style={styleBlock}
                    data-ad-client="ca-pub-7175286978469001"
                    data-ad-slot="7989089241"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
            </div>
        );
    }
}