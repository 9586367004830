import React, { Component, CSSProperties } from 'react'

const styleBlock: CSSProperties = {
    display: 'block',
}

export default class HealthDescription extends Component {
    render() {
        return (
            <div className="app-description">
                <h1>Zdravotní pojištění</h1>
                <p>OSVČ vykonávající vedlejší samostatnou výdělečnou činnost neplatí pravidelné měsíční zálohy na zdravotní pojištění. Zdravotní pojištění se doplácí jednorázově při podání přehledu o příjmech a výdajích na pojištovnu (nejpozději do jednoho měsíce ode dne, ve kterém měla podat daňové přiznání)</p>
                <p>Výše zdravotního pojištění je 13,5 % (jak pro OSVČ s hlavní, tak vedlejší činností) z vyměřovacího základu - polovina hrubého zisku (příjmy z podnikáni - výdaje z podnikání).</p>
                <p><b>Praktický příklad</b>: <i>Pan Novák byl po celý rok 2019 zaměstnaný a samostatnou (vedlejší) výdělečnou činností dosáhl za rok 2019 příjmy 100 000 Kč. Své výdaje si uplatňoval paušálem 60%.
                   <br />Hrubý zisk tedy činil 40 000 Kč (100 000 - 60 000). Vyměřovací základ pro zdravotní pojištění je tedy 20 000 Kč (40 000 Kč x 50 %).
                   <br /><br />Zdravotní pojištění za celý rok činí 2 700 Kč (20 000 Kč x 13,5 %).
                   <br /><br />Nedoplatek na zdravotním pojištění bude nutné zaplatit do 8. dní od doručení „Přehledu o příjmech a výdajích" na své příslušné zdravotní pojišťovně</i>
                </p>
                <ins className="adsbygoogle"
                    style={styleBlock}
                    data-ad-client="ca-pub-7175286978469001"
                    data-ad-slot="7989089241"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
            </div>
        );
    }
}